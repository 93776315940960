import { __decorate } from "tslib";
import { Component, Vue } from 'vue-property-decorator';
import BasePageHeader from '../components/BasePageHeader.vue';
import PasswordChange from '@/views/PersonalArea/userSetting/PasswordChange.vue';
let PasswordChangePage = class PasswordChangePage extends Vue {
};
PasswordChangePage = __decorate([
    Component({
        components: {
            PasswordChange,
            BasePageHeader,
        },
    })
], PasswordChangePage);
export default PasswordChangePage;
